export type DeprecatedToneName =
  | "blue"
  | "purple"
  | "gray"
  | "green"
  | "yellow"
  | "red"
  | "orange";
export type DeprecateTone =
  (typeof deprecatedTones)[keyof typeof deprecatedTones];
export const deprecatedTones = {
  white: "#ffffff",
  black: "#000000",
  brandYellow: "#feda65",
  brandSky: "#aebefd",
  brandBlue: "#4677f5",
  brandGreen: "#054540",
  brandLilac: "#775172",
  brandPurple: "#51184d",
  gray1: "#f7f7f8",
  gray2: "#e9eaec",
  gray3: "#dee0e3",
  gray4: "#c7c9d0",
  gray5: "#babdc5",
  gray6: "#9ea2ad",
  gray7: "#7e869a",
  gray8: "#717684",
  gray9: "#5e636e",
  gray10: "#464a53",
  gray11: "#333333",
  gray12: "#1f2228",
  gray13: "#14151a",
  gray1Alpha: "#0a0f290a",
  gray2Alpha: "#e9eaec14",
  gray3Alpha: "#dee0e323",
  gray4Alpha: "#c7c9d033",
  gray5Alpha: "#babdc53f",
  gray6Alpha: "#9ea2ad4c",
  gray7Alpha: "#7e869a66",
  gray8Alpha: "#7176847f",
  gray9Alpha: "#5e636e99",
  gray10Alpha: "#464a53b2",
  gray11Alpha: "#333333cc",
  gray12Alpha: "#1f2228e5",
  gray13Alpha: "#14151af2",
  blue1: "#f0f4fe",
  blue2: "#e3eafd",
  blue3: "#ccd9fa",
  blue4: "#b4c7f8",
  blue5: "#93aff6",
  blue6: "#7196f4",
  blue7: "#5984f2",
  blue8: "#4677f5",
  blue9: "#1d54e2",
  blue10: "#1a4ac7",
  blue11: "#13399a",
  blue12: "#07296a",
  blue13: "#03153a",
  blue1Alpha: "#3368f019",
  blue2Alpha: "#3368f026",
  blue3Alpha: "#3368f033",
  blue4Alpha: "#3368f03f",
  blue5Alpha: "#3368f04c",
  blue6Alpha: "#3368f066",
  blue7Alpha: "#3368f07f",
  blue8Alpha: "#3368f099",
  blue9Alpha: "#3368f0b2",
  blue10Alpha: "#3368f0cc",
  blue11Alpha: "#3368f0e5",
  green1: "#edfdf4",
  green2: "#d1fae4",
  green3: "#c3f8dc",
  green4: "#9af4c3",
  green5: "#8ce8b6",
  green6: "#6ae1a1",
  green7: "#40d986",
  green8: "#26bd6c",
  green9: "#21a65e",
  green10: "#1d9052",
  green11: "#166e3f",
  green1Alpha: "#26bd6c19",
  green2Alpha: "#26bd6c26",
  green3Alpha: "#26bd6c33",
  green4Alpha: "#26bd6c3f",
  green5Alpha: "#26bd6c4c",
  green6Alpha: "#26bd6c66",
  green7Alpha: "#26bd6c7f",
  green8Alpha: "#26bd6c99",
  green9Alpha: "#26bd6cb2",
  green10Alpha: "#26bd6ccc",
  green11Alpha: "#26bd6ce5",
  green12: "#0f4c2c",
  green13: "#072213",
  yellow1: "#fdfaec",
  yellow2: "#fdf7df",
  yellow3: "#fff3c2",
  yellow4: "#faebad",
  yellow5: "#f9e698",
  yellow6: "#fadf73",
  yellow7: "#f8d652",
  yellow8: "#f5cb26",
  yellow9: "#f1c105",
  yellow10: "#b49000",
  yellow11: "#8c7313",
  yellow12: "#403300",
  yellow13: "#201a01",
  yellow1Alpha: "#f1c10510",
  yellow2Alpha: "#f1c10515",
  yellow3Alpha: "#f1c10520",
  yellow4Alpha: "#f1c10525",
  yellow5Alpha: "#f1c10530",
  yellow6Alpha: "#f1c10540",
  yellow7Alpha: "#f1c10550",
  yellow8Alpha: "#f1c10560",
  yellow9Alpha: "#f1c10570",
  yellow10Alpha: "#f1c10580",
  yellow11Alpha: "#f1c10590",
  orange1: "#fef4ec",
  orange2: "#fdead8",
  orange3: "#fcddc0",
  orange4: "#fad0a9",
  orange5: "#f9c594",
  orange6: "#f8b577",
  orange7: "#f6a355",
  orange8: "#f48e2f",
  orange9: "#e9760c",
  orange10: "#d56c0b",
  orange11: "#ae590a",
  orange12: "#613105",
  orange13: "#301903",
  orange1Alpha: "#f48e2f19",
  orange2Alpha: "#f48e2f26",
  orange3Alpha: "#f48e2f33",
  orange4Alpha: "#f48e2f3f",
  orange5Alpha: "#f48e2f4c",
  orange6Alpha: "#f48e2f66",
  orange7Alpha: "#f48e2f7f",
  orange8Alpha: "#f48e2f99",
  orange9Alpha: "#f48e2fb2",
  orange10Alpha: "#f48e2fcc",
  orange11Alpha: "#f48e2fe5",
  red1: "#fdf2f1",
  red2: "#fce5e4",
  red3: "#f9d4d2",
  red4: "#f7c3c0",
  red5: "#f5b2ad",
  red6: "#f08b85",
  red7: "#eb6960",
  red8: "#e6483d",
  red9: "#d9281c",
  red10: "#be2318",
  red11: "#9a1c13",
  red12: "#64120d",
  red13: "#360a07",
  red1Alpha: "#e6483d19",
  red2Alpha: "#e6483d26",
  red3Alpha: "#e6483d33",
  red4Alpha: "#e6483d3f",
  red5Alpha: "#e6483d4c",
  red6Alpha: "#e6483d66",
  red7Alpha: "#e6483d7f",
  red8Alpha: "#e6483d99",
  red9Alpha: "#e6483db2",
  red10Alpha: "#e6483dcc",
  red11Alpha: "#e6483de5",
  purple1: "#f7f1fd",
  purple2: "#ecdffb",
  purple3: "#e1cdf9",
  purple5: "#cdaef4",
  purple4: "#d8c0f7",
  purple6: "#b78af0",
  purple7: "#a165eb",
  purple8: "#924ee8",
  purple9: "#731de1",
  purple10: "#6619c7",
  purple11: "#5314a3",
  purple12: "#390e70",
  purple13: "#1c0736",
  purple1Alpha: "#873de619",
  purple2Alpha: "#873de626",
  purple3Alpha: "#873de633",
  purple10Alpha: "#873de6cc",
  purple9Alpha: "#873de6b2",
  purple8Alpha: "#873de699",
  purple7Alpha: "#873de67f",
  purple6Alpha: "#873de666",
  purple5Alpha: "#873de64c",
  purple4Alpha: "#873de63f",
  purple11Alpha: "#873de6e5",
} as const;

export type DeprecatedColor =
  (typeof deprecatedColors)[keyof typeof deprecatedColors];

export const deprecatedColors: { [key: string]: string } = {
  primary: deprecatedTones.blue9,
  onPrimary: deprecatedTones.white,
  primaryHover: deprecatedTones.gray4Alpha,
  primaryContainer: deprecatedTones.blue3,
  onPrimaryContainer: deprecatedTones.blue13,
  disabled: deprecatedTones.gray5Alpha,
  onDisabled: deprecatedTones.gray10,
  background: deprecatedTones.white,
  onBackground: deprecatedTones.black,
  surface: deprecatedTones.gray3Alpha,
  onSurface: deprecatedTones.gray13,
  outline: deprecatedTones.gray8,
  border: deprecatedTones.gray5Alpha,
  error: deprecatedTones.red10,
  onError: deprecatedTones.white,
  errorContainer: deprecatedTones.red4,
  modalOverlay: "rgba(144, 149, 157, 0.4)",
  toggleGroupIconBackground: "rgba(123, 123, 137, 0.80)",
  courseGridHeader: "rgba(255, 255, 255, 0.05)",
  ai: deprecatedTones.purple8,
};

export type DeprecatedShadedTone = string;
export function deprecatedAlphaTone(
  hex: string,
  alpha: number,
): DeprecatedShadedTone {
  return `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, "0")}`;
}

export type DeprecatedRGBAColor = {
  r: number;
  g: number;
  b: number;
  a: number;
};

export function deprecatedHexToRGBA(hex: string): DeprecatedRGBAColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1,
      }
    : {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      };
}

export const deprecatedBlendRGBAColors = (
  base: DeprecatedRGBAColor,
  added: DeprecatedRGBAColor,
): string => {
  const mix = { r: 0, g: 0, b: 0, a: 0 };
  mix.a = 1 - (1 - added.a) * (1 - base.a); // alpha
  mix.r = Math.round(
    (added.r * added.a) / mix.a + (base.r * base.a * (1 - added.a)) / mix.a,
  ); // red
  mix.g = Math.round(
    (added.g * added.a) / mix.a + (base.g * base.a * (1 - added.a)) / mix.a,
  ); // green
  mix.b = Math.round(
    (added.b * added.a) / mix.a + (base.b * base.a * (1 - added.a)) / mix.a,
  ); // blue
  return `rgba(${mix.r}, ${mix.g}, ${mix.b}, ${mix.a})`;
};

// This function takes a brand color returns the background color for the home page billboard / header
// It gets this by blending tones.gray3Alpha (full opacity) with the brand color at 5% opacity
export const getDeprecatedBrandBillboardColor = (
  brandHexColor: string,
): string => {
  const brandColor = deprecatedHexToRGBA(brandHexColor);
  const gray1 = deprecatedHexToRGBA(deprecatedTones.gray1);
  return deprecatedBlendRGBAColors(gray1, {
    ...brandColor,
    a: 0.05,
  });
};

export function deprecatedShadeColor(hex: string, opacity = 1): string {
  const multiplier = 1 - opacity;
  const R_old = parseInt(hex.substring(1, 3), 16);
  const G_old = parseInt(hex.substring(3, 5), 16);
  const B_old = parseInt(hex.substring(5, 7), 16);
  const R_new = Math.min(Math.round(R_old + (255 - R_old) * multiplier), 255);
  const G_new = Math.min(Math.round(G_old + (255 - G_old) * multiplier), 255);
  const B_new = Math.min(Math.round(B_old + (255 - B_old) * multiplier), 255);
  const RR =
    R_new.toString(16).length === 1
      ? "0" + R_new.toString(16)
      : R_new.toString(16);
  const GG =
    G_new.toString(16).length === 1
      ? "0" + G_new.toString(16)
      : G_new.toString(16);
  const BB =
    B_new.toString(16).length === 1
      ? "0" + B_new.toString(16)
      : B_new.toString(16);
  return "#" + RR + GG + BB;
}

export function darkenColor(hex: string, factor = 1): string {
  factor = Math.max(0, Math.min(1, factor));

  const R = parseInt(hex.substring(1, 3), 16);
  const G = parseInt(hex.substring(3, 5), 16);
  const B = parseInt(hex.substring(5, 7), 16);

  const R_new = Math.round(R * (1 - factor));
  const G_new = Math.round(G * (1 - factor));
  const B_new = Math.round(B * (1 - factor));

  const RR = R_new.toString(16).padStart(2, "0");
  const GG = G_new.toString(16).padStart(2, "0");
  const BB = B_new.toString(16).padStart(2, "0");

  return "#" + RR + GG + BB;
}

export const gradients: { [key: string]: [string, string] } = {
  yellowViolet: ["#fff9c5", "#8e8bff"],
  indigoAqua: ["#dffff9", "#bfbaff"],
  redIndigo: ["#ffb9b9", "#c99fff"],
  orangeYellow: ["#ffc8c8", "#fff4cd"],
  cyanIndigo: ["#bbfbff", "#849fff"],
  greenBlue: ["#c9ffd5", "#6d9de6"],
  peachPurple: ["#a8acfb", "#ffdfef"],
  magentaPeach: ["#686fab", "#efccff"],
  yellowOrange: ["#f8ffe2", "#ffD4bc"],
  yellowPink: ["#fef1c2", "#feb0fb"],
  redPink: ["#ff9494", "#ffd2fd"],
  mintPink: ["#c6ffdc", "#f4b0ff"],
  pinkYellow: ["#e8bafb", "#eff9df"],
};

export const gradientsArray = Object.values(gradients);

export const isValidHexColor = (color: string): boolean => {
  return /^#[0-9A-F]{6}$/i.test(color);
};

export function addDeprecatedOpacityToHexColor(hex: string, alpha = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
